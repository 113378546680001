import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/article.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A 37 year old gentleman with no comorbidities came for asthenia, dyspnoea on exertion and chest heaviness since a few days. He is an athlete and can climb 10-12 flights of stairs without any discomfort, but is unable to do even 2 floors now.`}</p>
    <p>{`He was tested positive for SARS covid 2 infection in August and repeat RT-PCR was negative. `}</p>
    <p>{`On personal history – he says he has been smoking SOMETIMES since 8-9 years. “And how many per day?!” was my immediate query. `}</p>
    <p>{`Smokes for one year and complete abstinence for the next year`}</p>
    <p>{`Starts with 2-3 per day and keeps increasing slowly to one pack per day.`}</p>
    <p>{`Takes a glass of orange or musambi juice which protects the lungs from the damage of smoking!`}</p>
    <p>{`And stops after one year to cleanse his lung! `}</p>
    <p>{`His next due date to start is Feb. 9 th 2021, currently is abstinence year. `}</p>
    <p>{`Has no addiction to smoking according to him, but cannot leave the habit because he LIKES it so much and has a lot of work related stress. `}</p>
    <p>{`Now how did he come to the above smoking REGIMEN which can save from any damage related to smoking??`}</p>
    <p>{`The prompt response – “I have discussed and taken advice from a SENIOR SMOKIST who has been smoking for more than 20 years now without any problems to his lungs! I am under his supervision.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      